import {Text} from '@chakra-ui/react';
import React from 'react';
import {CreateDocument} from 'services/api';
import {colors} from '../constants';

interface Props {
  projectId: string;
}

const FileUploader = ({projectId}: Props): JSX.Element => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const changeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    const document = event?.currentTarget?.files?.[0] as File;
    if (!document.type.startsWith('image'))
      setErrorMessage('Kan endast ladda upp bilder');
    else if (projectId !== '') {
      const data = new FormData();
      data.append('ProjectId', projectId);
      data.append('File', document);
      data.append('Name', document.name);
      data.append('Folder', 'myImages');
      data.append('Type', document.type);
      const upload = await CreateDocument(data);
      if (!upload) {
        setErrorMessage('Kunde inte ladda upp bild');
      }
    } else {
      setErrorMessage('Kunde inte ladda upp bild');
    }
  };
  return (
    <div>
      <label
        htmlFor="file-upload"
        style={{
          border: 0,
          padding: 12,
          display: 'inline-block',
          paddingLeft: 24,
          paddingRight: 24,
          cursor: 'pointer',
          borderRadius: 4,
          backgroundColor: colors.primary[550],
          color: colors.secondary[700],
          fontWeight: 600,
          alignContent: 'center',
        }}>
        Ladda upp bild
      </label>
      <input
        id="file-upload"
        type="file"
        style={{display: 'none'}}
        onChange={changeHandler}
      />
      {!!errorMessage && (
        <Text my="1em" fontWeight="semibold" color="red">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};
export default FileUploader;
