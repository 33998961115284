import {Box, Flex, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {Content} from 'types/ContentTypes';
import {umbracoImageUrl} from '../../constants';

export function DoubleImageComponent(data: Content): JSX.Element {
  return (
    <Box>
      <Text mb="1em" variant="title">
        {data.Title}
      </Text>
      <Flex
        flexDirection={{base: 'column', md: 'row'}}
        justifyContent="space-between">
        <Image
          w={{base: '100%', md: '48%'}}
          mb="2em"
          src={umbracoImageUrl + data.ImageUrl}
          fit="contain"
        />
        <Image
          w={{base: '100%', md: '48%'}}
          mb="2em"
          src={umbracoImageUrl + data.SecondaryImageUrl}
          fit="contain"
        />
      </Flex>
    </Box>
  );
}
