import {combineReducers, configureStore, createSlice} from '@reduxjs/toolkit';
import {ContentState} from 'types/ContentTypes';
import {ProjectState} from 'types/NodeType';

const initialAuthState = {loggedIn: false};

const authSlice = createSlice({
  name: 'authStatus',
  initialState: initialAuthState,
  reducers: {
    setAuth: (state, action) => {
      state.loggedIn = action.payload;
    },
    resetAuth: () => {
      return initialAuthState;
    },
  },
});

export const {setAuth, resetAuth} = authSlice.actions;

//-----------------

const initialProjectState: ProjectState = {state: 'loading', project: null};

const projectSlice = createSlice({
  name: 'project',
  initialState: initialProjectState,
  reducers: {
    setProject: (state, action) => {
      state.state = 'finished';
      state.project = action.payload;
    },
    resetProject: () => {
      return initialProjectState;
    },
  },
});

export const {setProject, resetProject} = projectSlice.actions;

//-----------------

const initialContentState: ContentState = {state: 'loading', content: null};

const contentSlice = createSlice({
  name: 'content',
  initialState: initialContentState,
  reducers: {
    setContent: (state, action) => {
      state.state = 'finished';
      state.content = action.payload;
    },
    resetContent: () => {
      return initialContentState;
    },
  },
});

export const {setContent, resetContent} = contentSlice.actions;

//-----------------

const rootReducer = combineReducers({
  project: projectSlice.reducer,
  content: contentSlice.reducer,
  auth: authSlice.reducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: {
    project: projectSlice.reducer,
    auth: authSlice.reducer,
    content: contentSlice.reducer,
  },
});
