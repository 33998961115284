import {Flex} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {RootState} from 'redux/store';
import {ContentState} from 'types/ContentTypes';
import {InformationListItemComponent} from '../components/InformationListItemComponent';

interface Props {
  sm?: boolean;
  showAll?: boolean;
}

export default function InformationSectionComponent(props: Props): JSX.Element {
  const history = useHistory();
  const contentState = useSelector(
    (state: RootState) => state.content as ContentState,
  );

  return (
    <Flex
      flex="1"
      flexDirection={{base: 'column', md: 'row'}}
      justifyContent={{base: 'center', md: 'space-around'}}
      wrap="wrap">
      {contentState.content?.information?.Faqs?.map((info) => {
        if (props.showAll || info.ShowOnFrontPage)
          return (
            <InformationListItemComponent
              key={info.Id}
              item={info}
              onClick={() => history.push('/information/' + info.Name)}
              sm={props.sm}
            />
          );
      })}
    </Flex>
  );
}
