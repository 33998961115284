import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {ArrowButtonComponent} from '../components/ArrowButtonComponent';
import {RootState} from '../redux/store';
import {ProjectState} from '../types/NodeType';

type FolderParam = {
  folder: string;
};

export default function Documentfoldermenu(): JSX.Element {
  const history = useHistory();
  const [folders, setFolders] = React.useState<string[]>([]);
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );

  const {folder} = useParams<FolderParam>();

  React.useEffect(() => {
    const newFolders: string[] = [];
    state?.project?.Documents.map((doc) => {
      if (!newFolders.includes(doc.Folder)) newFolders.push(doc.Folder);
    });
    setFolders(newFolders);
  }, [folder, state]);

  return (
    <>
      {folders.map((folderName) => {
        if (folderName !== 'myImages')
          return (
            <ArrowButtonComponent
              key={folderName}
              title={folderName}
              onClick={() => {
                history.push('/account/' + folderName);
              }}
            />
          );
      })}
      <ArrowButtonComponent
        title={'Mina bilder'}
        onClick={() => {
          history.push('/account/myImages');
        }}
      />
    </>
  );
}
