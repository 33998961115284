export const isProd =
  process && process.env && process.env.NODE_ENV === 'production';

export const baseUrl = isProd
  ? 'https://svensnickare-customerportal-server-web.euwest01.umbraco.io/'
  : 'https://svensnickare-customerportal-server-web.euwest01.umbraco.io/';
//'http://localhost:4450';

export const apiVersion = '/api/v1';

export const umbracoImageUrl =
  'https://svensnickare-customerportal-server-web.euwest01.umbraco.io/';

const colors = {
  black: '#000000',
  lightGray: '#CDCDCD',
  white: '#ffffff',
  primary: {
    800: '#D05E38',
    550: 'rgba(142, 146, 127, .3)',
    500: '#8E927F',
    200: '#6874E8',
  },
  secondary: {
    800: '#303030',
    700: '#404040',
    500: '#E4E4E4',
    300: 'rgba(196, 196, 196, 0.4)',
    200: 'rgba(196, 196, 196, 0.1)',
    50: '#FFFDFA',
  },
  green: {
    500: '#93C385',
    300: 'rgba(195, 216, 187,0.6)',
  },
  yellow: {
    500: '#F2C831',
    400: '#FFDB5B',
    300: 'rgba(255, 219, 91,0.6)',
  },
  orange: {
    500: '#FFAA5B',
  },
  red: {
    500: '#C94C53',
    400: 'rgba(255, 40, 50, 0.6)',
    300: 'rgba(255, 107, 114, 0.6)',
  },
  system: {
    error: {
      900: '#800122',
      700: '#A5002C',
      500: '#D00A3F',
      300: '#F30041',
      100: '#FF6B72',
    },
    warning: {
      900: '#F2C831',
      700: '#FFDB5B',
      500: '#FFE27C',
      300: '#FFEEB2',
      100: '#FFF3C8',
    },
    success: {
      900: '#46A038',
      700: '#79CC6C',
      500: '#B8E4B1',
      300: '#C9EBC4',
      100: '#E4F5E2',
    },
  },
};

const apiBaseUrl = '';

const images = {
  logo: 'logo.png',
};

const icons = {
  logo: 'logo.png',
};

const resizeImage = {
  500: '?width=500',
  1000: '?width=1000',
  1500: '?width=1500',
};

export {colors, apiBaseUrl, icons, images, resizeImage};
