import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import menuIcon from 'assets/hamburger.svg';
import React from 'react';
import {useHistory} from 'react-router-dom';
import logo from '../assets/logo.png';
import {LogoutModal} from '../components/LogoutModal';
import {colors} from '../constants';

export function PageHeader(): JSX.Element {
  const history = useHistory();

  const {
    isOpen: logoutIsOpen,
    onOpen: logoutOnOpen,
    onClose: logoutOnClose,
  } = useDisclosure();

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      bg={{base: colors.secondary[200], md: colors.secondary[50]}}>
      <Image
        src={logo}
        alt="Sven snickare logga"
        w="123px"
        h="63px"
        my="15px"
        ml={{base: '2em', md: '4em'}}
        _hover={{cursor: 'pointer'}}
        onClick={() => {
          history.push('/');
        }}
      />
      <Flex align="center">
        <HeaderButtons />
        <MobileMenu />
      </Flex>
      <LogoutModal onClose={logoutOnClose} isOpen={logoutIsOpen} />
    </Flex>
  );

  function HeaderButtons(): JSX.Element {
    return (
      <Flex mr="2em" display={{base: 'none', md: 'flex'}}>
        <Button
          variant="menuButton"
          onClick={() => {
            history.push('/account');
          }}>
          Mina sidor
        </Button>
        <Button
          variant="menuButton"
          onClick={() => {
            history.push('/information');
          }}>
          Information
        </Button>
        <Button
          variant="menuButton"
          onClick={() => {
            history.push('/schema');
          }}>
          Schema
        </Button>
        <Button
          variant="menuButton"
          onClick={() => {
            history.push('/contact');
          }}>
          Kontakt
        </Button>
        <Button variant="menuButton" onClick={logoutOnOpen}>
          Logga ut
        </Button>
      </Flex>
    );
  }

  //
  function MobileMenu(): JSX.Element {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
      <Flex display={{base: 'flex', md: 'none'}}>
        <Image src={menuIcon} onClick={onOpen} mr="2em" />
        <Drawer size="full" isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent bg={colors.secondary[50]}>
              <DrawerCloseButton />
              <DrawerBody>
                <Flex
                  h="80%"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly">
                  <Button
                    variant="menuMobileButton"
                    width="250px"
                    onClick={() => {
                      onClose();
                      history.push('/account');
                    }}>
                    Mina sidor
                  </Button>
                  <Button
                    variant="menuMobileButton"
                    width="250px"
                    onClick={() => {
                      onClose();
                      history.push('/information');
                    }}>
                    Information
                  </Button>
                  <Button
                    variant="menuMobileButton"
                    width="250px"
                    onClick={() => {
                      onClose();
                      history.push('/schema');
                    }}>
                    Schema
                  </Button>
                  <Button
                    variant="menuMobileButton"
                    width="250px"
                    onClick={() => {
                      onClose();
                      history.push('/contact');
                    }}>
                    Kontakt
                  </Button>
                  <Button
                    variant="menuMobileButton"
                    width="250px"
                    onClick={() => {
                      onClose();
                      logoutOnOpen();
                    }}>
                    Logga ut
                  </Button>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Flex>
    );
  }
}
