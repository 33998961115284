import {Flex} from '@chakra-ui/react';
import {PageHeader} from 'components/PageHeader';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {LocationHeader} from '../components/LocationHeader';
import {PageFooter} from '../components/PageFooter';
import ScrollToTop from '../services/ScrollToTop';
import {AccountPage} from './AccountPage';
import {ContactPage} from './ContactPage';
import {CustomerImagesPage} from './CustomerImagesPage';
import {DocumentsPage} from './DocumentsPage';
import {InformationItemPage} from './InformationItemPage';
import {InformationPage} from './InformationPage';
import {SchemaPage} from './SchemaPage';
import {StartPage} from './StartPage';

export function PortalRoot(): JSX.Element {
  return (
    <Router>
      <ScrollToTop />
      <Flex flexDir={{base: 'column'}} flex="1" mt={{base: '60px', md: 0}}>
        <PageHeader />
        <LocationHeader />
        <Switch>
          <Route exact path="/information" component={InformationPage} />
          <Route
            exact
            path="/information/:id"
            component={InformationItemPage}
          />
          <Route
            exact
            path="/information/:id/:id"
            component={InformationItemPage}
          />
          <Route exact path="/account" component={AccountPage} />
          <Route exact path="/schema" component={SchemaPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route
            exact
            path="/account/myImages"
            component={CustomerImagesPage}
          />
          <Route exact path="/account/:folder" component={DocumentsPage} />
          <Route exact path="/*" component={StartPage} />
        </Switch>
        <PageFooter />
      </Flex>
    </Router>
  );
}
