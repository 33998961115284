import {Box, Flex, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {Content} from 'types/ContentTypes';
import {umbracoImageUrl} from '../../constants';

export function ImageWithTextComponent(data: Content): JSX.Element {
  return (
    <Box mb="1em">
      <Flex
        flexDirection={{base: 'column', md: 'row'}}
        justifyContent="space-between">
        <Image
          w={{base: '100%', md: '48%'}}
          mb={{base: '2em', md: '0'}}
          src={umbracoImageUrl + data.ImageUrl}
          fit="contain"
        />
        <Flex
          w={{base: '100%', md: '48%'}}
          flexDirection="column"
          justifyContent="center">
          <Text mb=".5em" variant="title">
            {data.Title}
          </Text>
          <div dangerouslySetInnerHTML={{__html: data.Text ?? ''}} />
        </Flex>
      </Flex>
    </Box>
  );
}
