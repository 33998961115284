import {Flex, Text} from '@chakra-ui/react';
import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {UpperCaseMyFirstLetter} from 'services/SimpleFunctions';
import {colors} from '../constants';

export function LocationHeader(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const [locationList, setLocationList] = React.useState(Array<string>());

  React.useEffect(() => {
    setLocationList(location.pathname.split('/'));
  }, [location.pathname]);

  const pathTranslation: Record<string, string> = {
    account: 'Mina sidor',
    information: 'Information',
    schema: 'Schema',
    contact: 'Kontakt',
    blueprints: 'Ritningar',
    documents: 'Dokument',
    images: 'Bilder',
    myImages: 'Mina bilder',
    quotations: 'Offerter',
  };

  return (
    <Flex
      display={location.pathname !== '/' ? 'flex' : 'none'}
      flexDir="row"
      alignItems="center"
      bg={colors.secondary[300]}
      py="1em"
      pl={{base: '2em', md: '4em'}}>
      {locationList.length > 1 &&
        locationList.map((path, x) => {
          return (
            <Flex key={x}>
              <Text
                onClick={() =>
                  history.push(location.pathname.split(path)[0] + path)
                }
                _hover={{cursor: 'pointer'}}
                fontWeight={x === locationList.length - 1 ? 'bold' : 'normal'}>
                {path === ''
                  ? 'Start'
                  : pathTranslation[path] !== undefined
                  ? pathTranslation[path]
                  : UpperCaseMyFirstLetter(path)}
              </Text>
              {!(x === locationList.length - 1) && (
                <Text mx=".5em">{' > '}</Text>
              )}
            </Flex>
          );
        })}
    </Flex>
  );
}
