import {AspectRatio, Box, Button, Flex, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {RootState} from 'redux/store';
import {ContentState} from 'types/ContentTypes';
import InformationSectionComponent from '../components/InformationSectionComponent';
import {colors, umbracoImageUrl} from '../constants';

export function StartPage(): JSX.Element {
  const history = useHistory();

  const contentState = useSelector(
    (state: RootState) => state.content as ContentState,
  );

  return (
    <Flex flex="1" flexDirection="column">
      <Flex
        bg={colors.primary[550]}
        w="100%"
        flexDirection={{base: 'column', md: 'row'}}>
        <Flex
          flex="1"
          flexDirection="column"
          alignItems={{xl: 'center'}}
          p={{base: '2em', md: '4em', lg: '5em'}}
          pt={{base: '3em', md: '5em', lg: '7em'}}
          w={{base: '100%', md: '50%'}}>
          <Box>
            <Text maxW="500px" variant="titleExpanding" mb="1em">
              {contentState?.content?.startpage.HeroTitle}
            </Text>
            <Text mb="2em" maxW="350px">
              {contentState?.content?.startpage.HeroText}
            </Text>
            <Button
              variant="outlineInverted"
              maxW="250px"
              onClick={() => history.push('/schema')}>
              Kom igång
            </Button>
          </Box>
        </Flex>
        <AspectRatio
          w={{base: '50%', xl: '60%'}}
          ratio={{base: 1, md: 4 / 3, lg: 8 / 5}}
          display={{base: 'none', md: 'flex'}}>
          <Image
            src={umbracoImageUrl + contentState?.content?.startpage.HeroImage}
            w="50%"
          />
        </AspectRatio>
      </Flex>
      <Text mx={{base: '2em'}} mt={{base: '2em'}} variant="titleBig">
        Information
      </Text>
      <Box p={{base: '2em', md: '4em'}}>
        <InformationSectionComponent />
      </Box>
    </Flex>
  );
}
