import {Flex, Image, Link, Text} from '@chakra-ui/react';
import React from 'react';

type CompModel = {
  icon: string;
  title: string;
  link: string;
};
export default function SocialMediaLink({
  icon,
  title,
  link,
}: CompModel): JSX.Element {
  return (
    <Link href={link} isExternal mx="1em" my="1em">
      <Flex alignItems="center">
        <Image src={icon} w="30px" mr="1em" />
        <Text fontSize="18px" fontWeight="semibold">
          {title}
        </Text>
      </Flex>
    </Link>
  );
}
