import {Box} from '@chakra-ui/react';
import React from 'react';
import {FaqUndifined} from 'types/ContentTypes';
import {BigImageComponent} from './BigImageComponent';
import {DoubleImageComponent} from './DoubleImageComponent';
import {ImageWithTextComponent} from './ImageWithTextComponent';
import {TextWithTitleComponent} from './TextWithTitleComponent';

export function ContentComponent(Faq: FaqUndifined): JSX.Element {
  return (
    <Box>
      {Faq?.Content?.map((content) => {
        switch (content?.BlockType) {
          case 'AppSingleImageBlock':
            return <BigImageComponent key={content.Id} {...content} />;
          case 'AppImageAndTextBlock':
            return <ImageWithTextComponent key={content.Id} {...content} />;
          case 'AppDoubleImageBlock':
            return <DoubleImageComponent key={content.Id} {...content} />;
          case 'AppTextAndTitle':
            return <TextWithTitleComponent key={content.Id} {...content} />;
          default:
            break;
        }
      })}
    </Box>
  );
}
