import {Flex, Image, Input} from '@chakra-ui/react';
import React from 'react';
import {colors} from '../constants';

type CompModel = {
  icon: string;
  title: string;
  placeholder: string;
  onChange: (e: string) => void;
};
export default function InputWithIcon({
  icon,
  title,
  placeholder,
  onChange,
}: CompModel): JSX.Element {
  return (
    <Flex alignItems="center" mb="1em" mr="1em">
      <Image src={icon} w="22px" mr="1em" />
      <Input
        maxW="400px"
        bg={colors.primary[550]}
        fontSize="18px"
        variant="outline"
        placeholder={placeholder}
        value={title}
        onChange={(val) => onChange(val.target.value)}
      />
    </Flex>
  );
}
