import auth from 'authur';
import {NewPasswordType} from 'types/ApiTypes';
import {ContactInfo, ICreateContactInfo, Node} from 'types/NodeType';
import {apiVersion, baseUrl} from '../constants';
import {setContent, setProject, store} from '../redux/store';

export async function GetProject(): Promise<void> {
  const resp = await auth.fetch('/completeproject/');
  const jsonObj = await resp.json();
  //console.log('PROJECT ->>>>>>>>>>>>>>>>>>>>>', jsonObj);
  if (resp.ok) {
    Promise.resolve;
    store.dispatch(setProject(jsonObj));
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
  //   resp?.ok
}

//Todo gör färdig med redux
export async function GetInformationContent(): Promise<void> {
  const resp = await auth.fetch('/cmscontent/');
  const jsonObj = await resp.json();
  // console.log('CONTENT ->>>>>>>>>>>>>>>>>>>>>', jsonObj);
  if (resp.ok) {
    Promise.resolve;
    store.dispatch(setContent(jsonObj));
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function GetLoginBackground(): Promise<string | undefined> {
  const resp = await auth.fetch('/cmscontent/loginbackground');
  const jsonObj = await resp.json();
  if (resp.ok) {
    Promise.resolve;
    return jsonObj.LoginImage;
  }
  Promise.reject;
  console.log('error: ' + resp);
  return undefined;
}

export async function UpdateNode(id: string, bodyObject: Node): Promise<void> {
  const body = JSON.stringify(bodyObject);
  const resp = await auth.fetch('/node/' + id, {
    method: 'PATCH',
    body: body,
  });
  console.log(resp);
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function CreateContact(
  contact: ICreateContactInfo,
  projectId: string,
): Promise<void> {
  const body = JSON.stringify(contact);
  const resp = await auth.fetch('/contactinfo/' + projectId, {
    method: 'PUT',
    body: body,
  });
  console.log(resp);
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function UpdateContact(contact: ContactInfo): Promise<void> {
  const body = JSON.stringify(contact);
  const resp = await auth.fetch('/contactinfo/' + contact.ContactInfoId, {
    method: 'PATCH',
    body: body,
  });
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function DeleteContact(contact: ContactInfo): Promise<void> {
  const resp = await auth.fetch('/contactinfo/' + contact.ContactInfoId, {
    method: 'DELETE',
  });
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function CreateDocument(data: FormData): Promise<boolean> {
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + (await auth.getToken()),
  };
  const resp = await fetch(baseUrl + apiVersion + '/document/', {
    method: 'PUT',
    headers: headers,
    body: data,
  });
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return true;
  }
  Promise.reject;
  console.log('error: ' + resp);
  return false;
}

export async function DeleteDocument(documentId: string): Promise<void> {
  const resp = await auth.fetch('/document/' + documentId, {
    method: 'DELETE',
  });
  if (resp.ok) {
    Promise.resolve;
    GetProject();
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function ResetPassword(email: string): Promise<void> {
  const body = JSON.stringify({email: email});
  const resp = await auth.fetch('/resetpassword/', {
    method: 'PUT',
    body: body,
  });
  if (resp.ok) {
    Promise.resolve;
    return;
  }
  Promise.reject;
  console.log('error: ' + resp);
}

export async function ChangePassword(
  passwords: NewPasswordType,
): Promise<boolean> {
  const body = JSON.stringify({
    oldPassword: passwords.Old,
    newPassword: passwords.New,
  });
  const resp = await auth.fetch('/password/', {
    method: 'PATCH',
    body: body,
  });
  if (resp.ok) {
    Promise.resolve;
    return await resp.json();
  }
  Promise.reject;
  console.log('error: ' + resp);
  return false;
}
