import {Box, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {Content} from 'types/ContentTypes';
import {colors, umbracoImageUrl} from '../../constants';

export function BigImageComponent(data: Content): JSX.Element {
  return (
    <Box mb="4em">
      <Text mb="1em" variant="title">
        {data.Title}
      </Text>
      <Image mb=".5em" src={umbracoImageUrl + data.ImageUrl} w="100%" />
      <Text fontSize="16px" fontStyle="italic" color={colors.secondary[700]}>
        {data.ImageDescription}
      </Text>
    </Box>
  );
}
