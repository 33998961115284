import {Flex, Image, Link, Text} from '@chakra-ui/react';
import externalIcon from 'assets/external.svg';
import React from 'react';
import {baseUrl, colors} from '../constants';
import {Document} from '../types/NodeType';

export function DocumentItem(Doc: Document): JSX.Element {
  return (
    <Link
      href={baseUrl + Doc.Path}
      isExternal={true}
      borderWidth="0"
      borderRadius="4px"
      bg={colors.primary[550]}
      w={{base: '100%'}}
      maxWidth="500px"
      flexDirection={'row'}
      color={colors.secondary[700]}
      py="1em"
      px="1em"
      mb="1em"
      justifyContent="space-between">
      <Flex flex="1" flexDirection="row" alignItems="center">
        <Text fontWeight={'semibold'} fontSize="18px">
          {Doc.Name}
        </Text>
        <Flex flex="1" />
        <Image src={externalIcon} h="16px" />
      </Flex>
    </Link>
  );
}
