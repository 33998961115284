import {Box, Image, SimpleGrid, Text} from '@chakra-ui/react';
import {ContentComponent} from 'components/ContentComponents/ContentComponent';
import InformationSectionComponent from 'components/InformationSectionComponent';
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {RootState} from 'redux/store';
import {GiveMeRightContent} from 'services/SimpleFunctions';
import {ContentState, Faq} from 'types/ContentTypes';
import {umbracoImageUrl} from '../constants';

export function InformationItemPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const [informationItem, setInformationItem] =
    React.useState<Faq | undefined>(undefined);
  const contentState = useSelector(
    (state: RootState) => state.content as ContentState,
  );
  React.useEffect(() => {
    setInformationItem(GiveMeRightContent(contentState, location.pathname));
  }, [contentState, location.pathname]);

  return (
    <Box flex="1" pb="200px">
      <Box
        mx={{base: '2em', md: '4em', lg: '8em'}}
        mt={{base: '2em', md: '4em'}}>
        <Text mb="1em" variant="titleBig">
          {informationItem?.Name}
        </Text>
        <Text mb="2em" maxW={{lg: '60%'}}>
          {informationItem?.Description}
        </Text>
        {informationItem?.FaqType === 'Group' && (
          <SimpleGrid columns={{base: 1, sm: 1, md: 2, xl: 3}} spacing={10}>
            {informationItem?.Faqs.map((faq) => (
              <Box
                _hover={{cursor: 'pointer'}}
                key={faq.Id}
                onClick={() =>
                  history.push(location.pathname + '/' + faq.Name)
                }>
                <Image
                  borderRadius="5px"
                  mb="1em"
                  src={umbracoImageUrl + faq.MenuImageUrl}
                />
                <Text variant="titleSmall">{faq.Name}</Text>
              </Box>
            ))}
          </SimpleGrid>
        )}
        <ContentComponent {...(informationItem ?? {})} />
        <Text my="2em" variant="title">
          Annan information
        </Text>
        <InformationSectionComponent sm={true} />
      </Box>
    </Box>
  );
}
