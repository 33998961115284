import {Flex} from '@chakra-ui/react';
import facebookIcon from 'assets/facebook.png';
import instagramIcon from 'assets/instagram.png';
import linkedinIcon from 'assets/linkedin.png';
import pinterestIcon from 'assets/pinterest.png';
import React from 'react';
import SocialMediaLink from '../components/SocialMediaLinkComponent';
import {colors} from '../constants';

const links = {
  facebook: 'https://www.facebook.com/svensnickare/',
  linkedin: 'https://se.linkedin.com/company/sven-snickare-ab',
  instagram: 'https://www.instagram.com/sven_snickare/',
  pintrest: 'https://www.pinterest.se/SvenSnickareAB/',
};

export function PageFooter(): JSX.Element {
  return (
    <Flex
      flexDir={{base: 'column', sm: 'row'}}
      bg={colors.secondary[300]}
      py="1em"
      pl={{base: '2em', md: '4em'}}>
      <SocialMediaLink
        title="Facebook"
        icon={facebookIcon}
        link={links.facebook}
      />
      <SocialMediaLink
        title="Instagram"
        icon={instagramIcon}
        link={links.instagram}
      />
      <SocialMediaLink
        title="Pinterest"
        icon={pinterestIcon}
        link={links.pintrest}
      />
      <SocialMediaLink
        title="LinkedIn"
        icon={linkedinIcon}
        link={links.linkedin}
      />
    </Flex>
  );
}
