import {Box, Button, Flex, Image, Input, Spinner, Text} from '@chakra-ui/react';
import logo from 'assets/logo.png';
import auth from 'authur';
import Resetpasswordcomponent from 'components/ResetPasswordComponent';
import React from 'react';
import {GetLoginBackground} from 'services/api';
import {colors, umbracoImageUrl} from '../constants';

export function LoginPage(): JSX.Element {
  const [state, setState] = React.useState('none');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetPassword, setResetPassword] = React.useState(false);
  const [backgroundImageUrl, setBackgroundImageUrl] =
    React.useState<string | undefined>('');

  React.useEffect(() => {
    async function GetImage() {
      setBackgroundImageUrl(await GetLoginBackground());
    }
    GetImage();
  }, []);

  const LoginFunction = async () => {
    setState('loading');
    const status = await auth.authenticate({username, password: password});
    status.ok ? setState('none') : setState('error');
  };

  return (
    <Flex
      flexDirection="column"
      h="100%"
      w="100%"
      alignItems="center"
      justifyContent="center"
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #010101 100%), url(' +
        umbracoImageUrl +
        backgroundImageUrl +
        ')'
      }
      //  bgImage={"url('" + imageSrc + "')"}
      bgPosition="fill"
      bgSize="cover"
      bgRepeat="no-repeat">
      <Image src={logo} position="absolute" top="30px" />
      <Flex
        flexDirection="column"
        bg={colors.secondary[50]}
        minW="400px"
        w="50%"
        maxW="700px"
        p="40px"
        opacity=".95"
        borderRadius="5px"
        alignItems="center"
        justifyContent="center">
        {state === 'loading' && <Spinner color={colors.primary[500]} />}
        {state !== 'loading' && !resetPassword && (
          <>
            <Text size="24px" as="h2" mb="40px">
              Välkommen tillbaka
            </Text>
            {state === 'error' && (
              <Text
                alignSelf="flex-start"
                fontSize="12px"
                textColor={colors.system.error[700]}>
                Fel, var god försök igen
              </Text>
            )}
            <Flex
              flexDirection={{base: 'column', sm: 'row'}}
              alignItems="center"
              justifyContent="space-between"
              mb="1em"
              w="100%">
              <Box w="48%">
                <Text
                  alignSelf="flex-start"
                  fontSize="12px"
                  textColor={colors.black}>
                  Användarnamn
                </Text>
                <Input
                  placeholder="AnnaJohansson"
                  borderRadius="5px"
                  borderColor={
                    state === 'error' ? colors.system.error[700] : 'none'
                  }
                  onChange={(val) => setUsername(val.target.value)}
                />
              </Box>
              <Box w="48%">
                <Text
                  alignSelf="flex-start"
                  fontSize="12px"
                  textColor={colors.black}>
                  Lösenord
                </Text>
                <Input
                  placeholder="*****"
                  type="password"
                  borderRadius="5px"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      LoginFunction();
                    }
                  }}
                  borderColor={
                    state === 'error' ? colors.system.error[700] : 'none'
                  }
                  onChange={(val) => setPassword(val.target.value)}
                />
              </Box>
            </Flex>
            <Button
              variant={state === 'error' ? 'error' : 'success'}
              w="100%"
              mb=".5em"
              onClick={() => {
                LoginFunction();
              }}>
              <Text textColor="white">
                {state === 'error' ? 'FÖRSÖK IGEN' : 'LOGGA IN'}
              </Text>
            </Button>
            <Text
              fontSize="14px"
              alignSelf="flex-start"
              textDecoration="underline"
              onClick={() => setResetPassword(true)}>
              Glömt lösenordet?
            </Text>
          </>
        )}
        {resetPassword && (
          <Resetpasswordcomponent ShowReset={setResetPassword} />
        )}
      </Flex>
    </Flex>
  );
}
