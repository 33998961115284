import {Box, Button, Flex, Input, Text} from '@chakra-ui/react';
import React, {Dispatch, SetStateAction} from 'react';
import {ResetPassword} from 'services/api';
import {colors} from '../constants';

interface Props {
  ShowReset: Dispatch<SetStateAction<boolean>>;
}

export default function Resetpasswordcomponent(prop: Props): JSX.Element {
  const [email, setEmail] = React.useState('');

  function HandleReset() {
    ResetPassword(email);
    prop.ShowReset(false);
  }

  return (
    <Box w="100%">
      <Text size="20px" as="h2" mb="1em">
        Ett nytt lösenord skickas till din epost.
      </Text>
      <Input
        placeholder="Fyll i din epost"
        title={email}
        bg={colors.primary[550]}
        fontSize="18px"
        variant="outline"
        mb="1em"
        onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(val.target.value)
        }
      />
      <Flex justify="space-between" gridGap={5}>
        <Button
          bg="green.500"
          color="white"
          disabled={status === 'loading'}
          isLoading={status === 'loading'}
          loadingText="Återställer..."
          flex={3}
          onClick={() => HandleReset()}>
          Återställ lösenord
        </Button>
        <Button flex={2} onClick={() => prop.ShowReset(false)}>
          Gå tillbaka
        </Button>
      </Flex>
    </Box>
  );
}
