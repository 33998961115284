import {Button, Flex, Image, Spacer, Text} from '@chakra-ui/react';
import check from 'assets/checkmark.svg';
import close from 'assets/close.svg';
import Lock from 'assets/lock.svg';
import React from 'react';
import {colors} from '../../constants';

interface ButtonProps {
  title: string | undefined;
  status: string;
  progress: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  deadlinePassed?: boolean;
}

const statusTranslation: Record<string, number> = {
  none: 0,
  incomplete: 1,
  complete: 2,
  passed: 3,
  warning: 4,
};

export default function ScheduleButtonComponent(
  props: ButtonProps,
): JSX.Element {
  return (
    <Button
      key={props.title}
      variant="ghost"
      justifyContent="flex-start"
      w="100%"
      mb=".5em"
      borderRadius="5px"
      py="2em"
      bg={
        statusTranslation[props.status] === 1
          ? colors.yellow[300]
          : statusTranslation[props.status] === 2
          ? colors.green[300]
          : statusTranslation[props.status] === 3
          ? colors.red[400]
          : statusTranslation[props.status] === 4
          ? colors.red[300]
          : ''
      }
      onClick={props.onClick}>
      <Flex w="100%" alignItems="center" flexWrap="wrap">
        <Text fontSize="18px" pr="1em">
          {props.title}
        </Text>
        <Spacer />
        {(statusTranslation[props.status] === 3 || props.deadlinePassed) && (
          <Image src={Lock} w="22px" mr="1em" />
        )}
        <Text mr="1em " w="3em">
          {props.progress}
        </Text>
        <Flex
          bg={
            statusTranslation[props.status] === 1
              ? colors.yellow[500]
              : statusTranslation[props.status] === 2
              ? colors.green[500]
              : statusTranslation[props.status] === 3
              ? colors.red[500]
              : ''
          }
          w="22px"
          h="22px"
          borderRadius="11"
          alignItems="center"
          justifyContent="center">
          {statusTranslation[props.status] > 1 && (
            <Image
              src={
                statusTranslation[props.status] === 2
                  ? check
                  : statusTranslation[props.status] === 3
                  ? close
                  : ''
              }
              w="12px"
            />
          )}
        </Flex>
      </Flex>
    </Button>
  );
}
