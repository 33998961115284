import {Button, Flex} from '@chakra-ui/react';
import avatarIcon from 'assets/avatar.svg';
import emailIcon from 'assets/email.svg';
import phoneIcon from 'assets/phone.svg';
import pinIcon from 'assets/pin.svg';
import InputWithIcon from 'components/InputWithIcon';
import LabelWithIcon from 'components/LabelWithIcon';
import React from 'react';
import {DeleteContact, UpdateContact} from 'services/api';
import {ContactInfo} from 'types/NodeType';

interface Props {
  contact: ContactInfo;
  projectId: string;
}

export function ContactInfoComponent(prop: Props): JSX.Element {
  const [editing, setEditing] = React.useState(false);
  const [contact, setContactCopy] = React.useState<ContactInfo>(prop.contact);

  async function Update() {
    UpdateContact(contact);
    setEditing(!editing);
  }
  async function Delete() {
    DeleteContact(contact);
    setEditing(!editing);
  }

  return (
    <Flex flexDirection="column" my="1em">
      {!editing && (
        <>
          <LabelWithIcon icon={avatarIcon} title={contact.Name} />
          {contact.Phone && (
            <LabelWithIcon icon={phoneIcon} title={contact.Phone} />
          )}
          {contact.Email && (
            <LabelWithIcon icon={emailIcon} title={contact.Email} />
          )}
          {contact.PostalAddress && (
            <LabelWithIcon icon={pinIcon} title={contact.PostalAddress} />
          )}
          {contact.DeliveryAddress && (
            <LabelWithIcon icon={pinIcon} title={contact.DeliveryAddress} />
          )}
          <Button variant="edit" size="sm" onClick={() => setEditing(!editing)}>
            Redigera
          </Button>
        </>
      )}
      {editing && (
        <>
          <InputWithIcon
            icon={avatarIcon}
            title={contact.Name}
            placeholder="Namn"
            onChange={(val) => setContactCopy({...contact, Name: val})}
          />
          <InputWithIcon
            icon={phoneIcon}
            title={contact.Phone}
            placeholder="Telefon"
            onChange={(val) => setContactCopy({...contact, Phone: val})}
          />
          <InputWithIcon
            icon={emailIcon}
            title={contact.Email}
            placeholder="Epost"
            onChange={(val) => setContactCopy({...contact, Email: val})}
          />
          <InputWithIcon
            icon={pinIcon}
            title={contact.PostalAddress}
            placeholder="Postadress"
            onChange={(val) => setContactCopy({...contact, PostalAddress: val})}
          />
          <InputWithIcon
            icon={pinIcon}
            title={contact.DeliveryAddress}
            placeholder="Leveransadress"
            onChange={(val) =>
              setContactCopy({...contact, DeliveryAddress: val})
            }
          />
          <Flex>
            <Button
              variant="success"
              width="140px"
              size="sm"
              mr="1em"
              onClick={() => Update()}>
              Spara
            </Button>
            <Button
              variant="error"
              width="140px"
              size="sm"
              mr="1em"
              onClick={() => Delete()}>
              Ta bort
            </Button>
            <Button
              variant="edit"
              width="140px"
              size="sm"
              onClick={() => setEditing(false)}>
              Avbryt
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
}
