import {
  AspectRatio,
  Box,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import {Faq} from 'types/ContentTypes';
import {resizeImage, umbracoImageUrl} from '../constants';
import {ArrowButtonComponent} from './ArrowButtonComponent';

type CompModel = {
  item: Faq;
  onClick:
    | React.MouseEventHandler<HTMLButtonElement>
    | React.MouseEventHandler<HTMLDivElement>;
  sm?: boolean;
};
export function InformationListItemComponent({
  item,
  onClick,
  sm,
}: CompModel): JSX.Element {
  const desc =
    item.Description.length > 160
      ? item.Description.slice(0, 157) + '...'
      : item.Description;
  const small = useBreakpointValue({base: true, md: false});
  return (
    <>
      {!small && (
        <Box
          mx="1em"
          mb="2em"
          maxW={sm ? '250px' : '400px'}
          minW={'250px'}
          onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
          _hover={{cursor: 'pointer'}}>
          <AspectRatio ratio={3 / 5} mb="1em">
            <Image
              src={umbracoImageUrl + item.MenuImageUrl + resizeImage[500]}
            />
          </AspectRatio>
          <Text variant="titleSmall" mb="1em">
            {item.Name}
          </Text>
          <Text mb="1em">{desc}</Text>
        </Box>
      )}
      {small && (
        <ArrowButtonComponent
          title={item.Name}
          onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        />
      )}
    </>
  );
}
