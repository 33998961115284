import {Box, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {ContentState} from 'types/ContentTypes';
import InformationSectionComponent from '../components/InformationSectionComponent';

export function InformationPage(): JSX.Element {
  const contentState = useSelector(
    (state: RootState) => state.content as ContentState,
  );
  return (
    <Box flex="1">
      <Box
        mx={{base: '2em', md: '4em', lg: '8em'}}
        mt={{base: '2em', md: '4em'}}>
        <Text mb="1em" variant="titleBig">
          Information
        </Text>
        <Text>{contentState.content?.information.Description ?? ''}</Text>
      </Box>
      <Box p={{base: '2em', md: '4em'}}>
        <InformationSectionComponent showAll={true} />
      </Box>
    </Box>
  );
}
