import {Flex, Text} from '@chakra-ui/react';
import Addcontactcomponent from 'components/AddContactComponent';
import ChangePasswordComponent from 'components/ChangePasswordComponent';
import Documentfoldermenu from 'components/DocumentFolderMenu';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {ProjectState} from 'types/NodeType';
import {ContactInfoComponent} from '../components/ContactInfoComponent';

export function AccountPage(): JSX.Element {
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );

  return (
    <Flex
      flexDirection={{base: 'column', lg: 'row'}}
      mx={{base: '2em', md: '4em', lg: '8em'}}
      mt={{base: '2em', md: '4em'}}
      flex="1"
      pb="2em">
      <Flex flex="1" flexDirection="column">
        <Text mb="2em" variant="title">
          Mina kontaktuppgifter
        </Text>
        {state.project?.ContactInfos
          ? state.project?.ContactInfos.map((contact) => (
              <ContactInfoComponent
                key={contact.Id}
                contact={contact}
                projectId={state.project?.ProjectId ?? ''}
              />
            ))
          : null}
        <Addcontactcomponent projectId={state.project?.ProjectId ?? ''} />
        <ChangePasswordComponent />
      </Flex>
      <Flex flex="1" mt="1em" flexDirection="column">
        <Text mb="2em" variant="title">
          Dokument
        </Text>
        <Documentfoldermenu />
      </Flex>
    </Flex>
  );
}
