import {Flex, Image, Text} from '@chakra-ui/react';
import React from 'react';

type CompModel = {
  icon: string;
  title: string;
  weight?: string;
};
export default function LabelWithIcon({
  icon,
  title,
  weight = 'regular',
}: CompModel): JSX.Element {
  return (
    <Flex alignItems="center" mb="1.5em">
      <Image src={icon} w="22px" mr="1em" />
      <Text fontSize="18px" fontWeight={weight}>
        {title}
      </Text>
    </Flex>
  );
}
