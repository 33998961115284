import {Flex, Text} from '@chakra-ui/react';
import {DocumentItem} from 'components/DocumentItem';
import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from 'redux/store';
import {Document, ProjectState} from 'types/NodeType';

type FolderParam = {
  folder: string;
};

export function DocumentsPage(): JSX.Element {
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );

  const {folder} = useParams<FolderParam>();

  React.useEffect(() => {
    const newDocuments: Document[] = [];
    state?.project?.Documents.map((doc) => {
      if (doc.Folder === folder) newDocuments.push(doc);
    });
    setDocuments(newDocuments);
  }, [folder, state]);

  return (
    <Flex
      flexDirection={{base: 'column'}}
      mx={{base: '2em', md: '8em'}}
      mt={{base: '2em', md: '4em'}}
      flex="1"
      pb="2em">
      <Text mb="2em" variant="title">
        {folder}
      </Text>
      {documents.map((doc) => {
        return <DocumentItem key={doc.Id} {...doc} />;
      })}
      {/* <Flex flex="1" mt="1em" flexDirection="column">
        <Text mb="2em" variant="title">
          Aktuellt för dig
        </Text>
        <Documentfoldermenu />
      </Flex> */}
    </Flex>
  );
}
