import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {colors} from '../../constants';
import {Node} from '../../types/NodeType';

interface NodeWithChangeFunction {
  Content: Node;
  ChangeValue(value: string): void;
  Disabled: boolean;
  ParentValue: string;
}

export default function ScheduleInputComponent(
  data: NodeWithChangeFunction,
): JSX.Element {
  const [nodeValue, setNodeValue] = React.useState(data.Content.Value);
  const nodeList = [...(data?.Content?.Nodes ?? [])];

  switch (data.Content.Type) {
    case 'thisOrThat':
      return (
        <Flex my="1em">
          {data.Content?.Nodes?.map((node) => (
            <Button
              key={node.NodeId}
              mr="1em"
              isDisabled={data.Disabled}
              variant={
                nodeValue === node.Name
                  ? 'thisOrThatDark'
                  : 'thisOrThatTransparent'
              }
              _focus={{outline: 'none'}}
              onClick={() => {
                setNodeValue(node.Name);
                data.ChangeValue(node.Name);
              }}>
              {node.Name}
            </Button>
          ))}
        </Flex>
      );
    case 'text':
      return (
        <Input
          isDisabled={data.Disabled}
          value={nodeValue}
          h="50px"
          _focus={{borderColor: colors.secondary[700]}}
          bg={colors.secondary[50]}
          borderColor={colors.secondary[700]}
          onChange={(e) => setNodeValue(e.target.value)}
          onBlur={(e) => data.ChangeValue(e.target.value)}
        />
      );
    case 'multiRadio':
      return (
        <RadioGroup
          isDisabled={data.Disabled}
          onChange={(val) => {
            if (!data.Disabled) {
              setNodeValue(val);
              data.ChangeValue(val);
            }
          }}
          value={nodeValue}>
          <Stack>
            {!!nodeList.length &&
              nodeList
                .sort((a, b) => a.SortOrder - b.SortOrder)
                .map((node) => {
                  switch (node.Type) {
                    case 'text':
                      return (
                        <Input
                          key={node.Id}
                          fontSize="14px"
                          isDisabled={data.Disabled}
                          placeholder="Annat.."
                          borderWidth={
                            nodeList.filter((e) => e.Name === nodeValue)
                              .length === 0
                              ? '2px'
                              : '1px'
                          }
                          value={
                            nodeList.filter((e) => e.Name === nodeValue)
                              .length > 0
                              ? ''
                              : nodeValue
                          }
                          h="30px"
                          my="4px"
                          _focus={{borderColor: colors.secondary[700]}}
                          bg={colors.secondary[50]}
                          borderColor={colors.secondary[700]}
                          onChange={(e) => setNodeValue(e.target.value)}
                          onBlur={(e) => data.ChangeValue(e.target.value)}
                        />
                      );
                    default:
                      return (
                        <Radio
                          // isDisabled={data.Disabled}
                          key={node.Id}
                          size="lg"
                          borderColor={colors.lightGray}
                          _checked={{
                            bg: colors.secondary[800],
                            borderColor: colors.secondary[200],
                          }}
                          _focus={{}}
                          pb="18px"
                          value={node.Name}>
                          <Text
                            ml="7px"
                            fontSize="14px"
                            fontWeight={
                              nodeValue === node.Name ? 'bold' : 'regular'
                            }
                            color={colors.secondary[700]}>
                            {node.Name}
                          </Text>
                        </Radio>
                      );
                  }
                })}
          </Stack>
        </RadioGroup>
      );
    case 'radio':
      return (
        <Radio
          isDisabled={data.Disabled}
          key={data.Content.NodeId}
          size="lg"
          borderColor={colors.lightGray}
          _checked={{
            bg: colors.secondary[800],
            borderColor: colors.secondary[200],
          }}
          pb="18px"
          name="1"
          isChecked={data.Content.Name === nodeValue}
          value={data.Content.Name}
          onClick={() => {
            setNodeValue(
              data.Content.Name === nodeValue ? '' : data.Content.Name,
            );
            data.ChangeValue(
              data.Content.Name === nodeValue ? '' : data.Content.Name,
            );
          }}>
          <Text ml="7px" fontSize="14px" color={colors.secondary[700]}>
            {data.Content.Name}
          </Text>
        </Radio>
      );
    case 'boolean': {
      const isChecked = nodeValue === data.Content.Name;
      return (
        <Flex justifyItems="center">
          <Checkbox
            isDisabled={data.Disabled}
            _focus={{
              boxShadow: 'none',
            }}
            mr="1em"
            colorScheme="blackAlpha"
            size="lg"
            isChecked={isChecked}
            borderColor={colors.lightGray}
            onChange={(e) => {
              if (e.target.checked) {
                setNodeValue(data.Content.Name);
                data.ChangeValue(data.Content.Name);
              } else {
                setNodeValue('');
                data.ChangeValue('');
              }
            }}
          />
          <Text fontSize="14px" color={colors.secondary[800]}>
            {data.Content.Description}
          </Text>
        </Flex>
      );
    }
    case 'information':
      return <div />;
    default:
      break;
  }
  return (
    <Box>
      <Text>Fel</Text>
    </Box>
  );
}
