// chakra
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
// redux
import {Provider} from 'react-redux';
import App from './App';
import {colors} from './constants';
import './index.css';
import {store} from './redux/store';
import reportWebVitals from './reportWebVitals';

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        borderRadius: 4,
        bg: colors.primary[550],
      },
      variants: {
        darkSolid: {
          bg: colors.primary[500],
          color: colors.white,
        },
        solid: {
          bg: colors.primary[550],
          color: colors.secondary[700],
          borderWidth: 0,
        },
        ml: {
          bg: colors.primary[800],
          color: colors.white,
        },
        success: {
          bg: colors.system.success[700],
          color: colors.white,
        },
        error: {
          bg: colors.system.error[700],
          color: colors.white,
        },
        outline: {
          color: colors.secondary[700],
          bg: colors.white,
          border: '1px solid',
          borderColor: colors.secondary[700],
        },
        outlineInverted: {
          color: colors.white,
          bg: colors.secondary[700],
          border: '1px solid',
          borderColor: colors.white,
        },
        menuButton: {
          borderWidth: 0,
          fontSize: 14,
          textColor: colors.secondary[700],
          ml: '2em',
          bg: 'none',
          maxW: '200px',
        },
        menuMobileButton: {
          borderWidth: 0,
          fontSize: 24,
          textColor: colors.secondary[700],
          fontWeight: 'bold',
        },
        edit: {
          borderWidth: 0,
          fontSize: 14,
          textColor: colors.white,
          width: '140px',
          backgroundColor: colors.orange[500],
        },
        thisOrThatDark: {
          borderRadius: 4,
          width: '80px',
          height: '50px',
          bg: colors.secondary[800],
          color: colors.white,
          borderWidth: 1,
          borderColor: colors.secondary[800],
        },
        thisOrThatTransparent: {
          borderRadius: 4,
          width: '80px',
          height: '50px',
          bg: '',
          borderWidth: 1,
          borderColor: colors.primary[500],
        },
      },
    },
    Text: {
      baseStyle: {
        color: colors.secondary[800],
      },
      variants: {
        title: {
          fontSize: '24px',
          fontWeight: 'bold',
        },
        titleExpanding: {
          fontSize: {base: '32px', sm: '42px', lg: '52px'},
          fontWeight: 'bold',
        },
        titleBig: {
          fontSize: '32px',
          fontWeight: 'bold',
        },
        titleSmall: {
          fontSize: '20px',
          fontWeight: 'bold',
        },
      },
    },
    Image: {
      baseStyle: {
        borderRadius: '5px',
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
