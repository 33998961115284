import {Flex, Text} from '@chakra-ui/react';
import {ContactComponent} from 'components/ContactComponent';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {ProjectState} from 'types/NodeType';

export function ContactPage(): JSX.Element {
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );
  return (
    <Flex flex="1" flexDirection="column">
      <Flex
        flexDirection="column"
        mx={{base: '2em', md: '4em', lg: '8em', xl: '12em'}}
        mt={{base: '2em', md: '4em'}}
        flex="1"
        pb="2em">
        <Text variant="title" mb="2em">
          Kontaktuppgifter
        </Text>
        <Flex flexDirection={{base: 'column', lg: 'row'}} wrap="wrap">
          {state?.project?.Contractors &&
            state.project?.Contractors.map((contact) => {
              return <ContactComponent {...contact} />;
            })}
        </Flex>
      </Flex>
    </Flex>
  );
}
