import {Flex, Text} from '@chakra-ui/react';
import avatarIcon from 'assets/avatar.svg';
import emailIcon from 'assets/email.svg';
import phoneIcon from 'assets/phone.svg';
import store from 'assets/store.svg';
import LabelWithIcon from 'components/LabelWithIcon';
import React from 'react';
import {Contractor} from 'types/NodeType';

export const ContactComponent = (props: Contractor): JSX.Element => {
  return (
    <Flex flex="1" flexDirection="column" mb="3em" mx=".5em" minW="400px">
      <Text mb="1em" fontWeight="bold" fontSize="18px">
        {props.Occupation}
      </Text>
      <LabelWithIcon icon={avatarIcon} title={props.Name} />
      {props.Phone && <LabelWithIcon icon={phoneIcon} title={props.Phone} />}
      {props.Email && <LabelWithIcon icon={emailIcon} title={props.Email} />}
      {props.Company && <LabelWithIcon icon={store} title={props.Company} />}
    </Flex>
  );
};
