import {Flex} from '@chakra-ui/layout';
import {Spinner} from '@chakra-ui/spinner';
import auth from 'authur';
import {LoginPage} from 'pages/LoginPage';
import {PortalRoot} from 'pages/PortalRoot';
import React from 'react';
import './App.css';
import {baseUrl, colors} from './constants';
import {GetInformationContent, GetProject} from './services/api';

function App(): JSX.Element {
  //   const [userStatus, setUserStatus] = React.useState('loading');
  const [isLoggedIn, setIsLoggedIn] = React.useState('loading');

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChange((status: boolean) => {
      if (status) {
        GetProject();
        GetInformationContent();
      }
    });

    unsubscribe();
  }, [isLoggedIn]);

  auth.initialize({
    origin: baseUrl,
    authPath: '/oauth/app',
    apiPath: '/api/v1',
    persistenceGet: (key: string) => localStorage.getItem(key),
    persistenceSet: (key: string, val: string) =>
      localStorage.setItem(key, val),
    persistenceClear: (storageKey: string) =>
      localStorage.removeItem(storageKey),
    debug: false,
    events: {
      onAuthStateChange: (status: string) => setIsLoggedIn(status),
    },
  });

  return (
    <Flex flex="1">
      {isLoggedIn === 'loading' ? (
        <Flex style={{position: 'fixed', top: '50%', left: '50%'}}>
          <Spinner color={colors.primary[800]} />
        </Flex>
      ) : isLoggedIn ? (
        <PortalRoot />
      ) : (
        !isLoggedIn && <LoginPage />
      )}
    </Flex>
  );
}

export default App;
