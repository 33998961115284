import {Box, Flex, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {GiveMePrintableDate} from 'services/SimpleFunctions';
import {ProjectState} from 'types/NodeType';
import ScheduleComponent from '../components/ScheduleComponents/ScheduleComponent';
import {colors} from '../constants';

export function SchemaPage(): JSX.Element {
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );
  return (
    <Flex
      flex="1"
      flexDirection={{base: 'column'}}
      mx={{base: '2em', md: '4em', xl: '8em'}}
      mt={{base: '2em', md: '4em'}}>
      <Flex flexDirection={{base: 'column', lg: 'row'}}>
        <Box flex="1" mb={{base: '2em', lg: '0em'}}>
          <Flex flexDirection="row">
            <Text variant="titleSmall" mr=".5em">
              Dina snickerier är planerade att börja tillverkas:
            </Text>
            <Text variant="titleSmall" color={colors.orange[500]}>
              {state.project?.ManufacturingWhen
                ? GiveMePrintableDate(state.project?.ManufacturingWhen as Date)
                : 'Inte inlagt ännu.'}
            </Text>
          </Flex>
          <Flex flexDirection="row" mb="1em">
            <Text fontSize="18px" fontWeight="bold" mr=".5em">
              Leverans möjligt från:
            </Text>
            <Text fontSize="18px" fontWeight="bold" color={colors.orange[500]}>
              {state.project?.DeliveryWhen
                ? GiveMePrintableDate(state.project?.DeliveryWhen as Date)
                : 'Inte inlagt ännu.'}
            </Text>
          </Flex>
          <Text mb="2em">
            För att detta ska vara möjligt behöver vi besked enligt schemat här
            nedan
          </Text>
        </Box>
      </Flex>
      <ScheduleComponent />
    </Flex>
  );
}
