import {Box, Text} from '@chakra-ui/react';
import React from 'react';
import {UpdateNode} from 'services/api';
import {colors} from '../../constants';
import {Node} from '../../types/NodeType';
// import {CheckNodeProperties} from './../../services/NodeFunctions';
import ScheduleInputComponent from './ScheduleInputComponent';

interface Props {
  Node: Node;
  Disabled: boolean;
}

export default function ScheduleNodeComponent(Content: Props): JSX.Element {
  async function ChangeValue(value: string) {
    const newNode = {
      Name: Content.Node.Name,
      Id: Content.Node.Id,
      NodeId: Content.Node.NodeId,
      GroupId: Content.Node.GroupId,
      NodeProperties: Content.Node.NodeProperties,
      ParentId: Content.Node.ParentId,
      Description: Content.Node.Description,
      SortOrder: Content.Node.SortOrder,
      Type: Content.Node.Type,
      Value: value,
    };

    UpdateNode(Content.Node.NodeId, newNode);
  }

  return (
    <Box mt="31px" mb="56px">
      <Text
        fontWeight="semibold"
        fontSize="18px"
        mb="20px"
        color={colors.secondary[800]}>
        {Content.Node.Name}
      </Text>
      {Content.Node.Type !== 'boolean' && (
        <Text fontSize="14px" mb="20px" color={colors.secondary[800]}>
          {Content.Node.Description}
        </Text>
      )}
      <ScheduleInputComponent
        ParentValue={Content.Node.Value}
        Disabled={Content.Disabled}
        Content={Content.Node}
        ChangeValue={ChangeValue}
      />
    </Box>
  );
}
