import {Box, Button, Flex} from '@chakra-ui/react';
import avatarIcon from 'assets/avatar.svg';
import emailIcon from 'assets/email.svg';
import phoneIcon from 'assets/phone.svg';
import pinIcon from 'assets/pin.svg';
import React from 'react';
import {ICreateContactInfo} from 'types/NodeType';
import InputWithIcon from '../components/InputWithIcon';
import {CreateContact} from '../services/api';

interface Props {
  projectId: string;
}

export default function Addcontactcomponent(prop: Props): JSX.Element {
  const [creating, setCreating] = React.useState(false);

  const [contact, setContactCopy] = React.useState<ICreateContactInfo>(
    {} as ICreateContactInfo,
  );

  function Create() {
    CreateContact(contact, prop.projectId);
    setCreating(false);
    setContactCopy({} as ICreateContactInfo);
  }

  return (
    <Box mt="2em">
      {creating && (
        <Box>
          <InputWithIcon
            icon={avatarIcon}
            title={contact.Name}
            placeholder="Namn"
            onChange={(val) => setContactCopy({...contact, Name: val})}
          />
          <InputWithIcon
            icon={phoneIcon}
            title={contact.Phone}
            placeholder="Telefon"
            onChange={(val) => setContactCopy({...contact, Phone: val})}
          />
          <InputWithIcon
            icon={emailIcon}
            title={contact.Email}
            placeholder="Epost"
            onChange={(val) => setContactCopy({...contact, Email: val})}
          />
          <InputWithIcon
            icon={pinIcon}
            title={contact.PostalAddress}
            placeholder="Postadress"
            onChange={(val) => setContactCopy({...contact, PostalAddress: val})}
          />
          <InputWithIcon
            icon={pinIcon}
            title={contact.DeliveryAddress}
            placeholder="Leveransadress"
            onChange={(val) =>
              setContactCopy({...contact, DeliveryAddress: val})
            }
          />
        </Box>
      )}
      <Flex>
        {creating && (
          <>
            <Button
              variant="success"
              width="140px"
              size="sm"
              mr="1em"
              onClick={() => Create()}>
              Spara
            </Button>
            <Button
              variant="edit"
              width="140px"
              size="sm"
              onClick={() => setCreating(false)}>
              Avbryt
            </Button>
          </>
        )}
        {!creating && (
          <Button
            variant={'success'}
            width="140px"
            size="sm"
            onClick={() => setCreating(true)}>
            Lägg till kontakt
          </Button>
        )}
      </Flex>
    </Box>
  );
}
