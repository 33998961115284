import {Box, Button, Flex, Image, SimpleGrid, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {DeleteDocument} from 'services/api';
import {ProjectState} from 'types/NodeType';
import FileUploader from '../components/FileUploader';
import {umbracoImageUrl} from '../constants';

export function CustomerImagesPage(): JSX.Element {
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );

  return (
    <Flex
      flexDirection={{base: 'column'}}
      mx={{base: '2em', md: '8em'}}
      mt={{base: '2em', md: '4em'}}
      flex="1"
      pb="2em">
      <Text mb="2em" variant="title">
        Mina bilder
      </Text>
      <FileUploader projectId={state.project?.ProjectId ?? ''} />
      <SimpleGrid
        columns={{base: 1, sm: 1, md: 2, xl: 3}}
        spacing={10}
        mt="1em">
        {state.project?.Documents.map((document) => {
          if (document.Folder === 'myImages') {
            return (
              <Box key={document.DocumentId}>
                <Image src={umbracoImageUrl + document.Path} />
                <Button
                  variant="edit"
                  size="sm"
                  my="1em"
                  width="100px"
                  onClick={() => DeleteDocument(document.DocumentId)}>
                  Ta bort
                </Button>
              </Box>
            );
          }
        })}
      </SimpleGrid>
      {/* <Flex flex="1" mt="1em" flexDirection="column">
        <Text mb="2em" variant="title">
          Aktuellt för dig
        </Text>
        <Documentfoldermenu />
      </Flex> */}
    </Flex>
  );
}
