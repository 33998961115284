import {ContentState, Faq} from 'types/ContentTypes';

export function UpperCaseMyFirstLetter(word: string): string {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1);
}

export function GiveMePrintableDate(date: Date, full = true): string {
  const dt = new Date(date);
  let month = (dt.getMonth() + 1).toString();
  let day = dt.getDate().toString().toString();
  let year = dt.getFullYear().toString();

  if (!full) year = year.substr(-2);

  if (month.length === 1) {
    month = '0' + month;
  }

  if (day.length === 1) {
    day = '0' + day;
  }

  return day + '/' + month + ' - ' + year;
}

export function GiveMeRightContent(
  contentState: ContentState,
  informationName: string,
): Faq | undefined {
  let currentInfo: Faq | undefined = undefined;
  contentState.content?.information?.Faqs?.map((obj: Faq) => {
    if (obj?.Faqs) {
      obj.Faqs.map((underFaq) => {
        const n = informationName?.lastIndexOf('/');
        const name =
          underFaq.Name.substr(-1) === '?'
            ? informationName.substring(n + 1) + '?'
            : underFaq.Name.substr(-1) === '!'
            ? informationName.substring(n + 1) + '!'
            : underFaq.Name.substr(-1) === '.'
            ? informationName.substring(n + 1) + '.'
            : informationName.substring(n + 1);
        if (underFaq.Name === name) currentInfo = underFaq;
      });
    }

    const n = informationName?.lastIndexOf('/');
    const name =
      obj.Name.substr(-1) === '?'
        ? informationName.substring(n + 1) + '?'
        : obj.Name.substr(-1) === '!'
        ? informationName.substring(n + 1) + '!'
        : obj.Name.substr(-1) === '.'
        ? informationName.substring(n + 1) + '.'
        : informationName.substring(n + 1);
    if (obj.Name === name) {
      currentInfo = obj;
    }
  });
  return currentInfo;
}

export function AddDaysToDate(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function RemoveTimeFromDate(date: Date): Date {
  date.setHours(0, 0, 0, 0);
  return date;
}
