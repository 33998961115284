import {Box, Text} from '@chakra-ui/react';
import React from 'react';
import {Content} from 'types/ContentTypes';

export function TextWithTitleComponent(data: Content): JSX.Element {
  return (
    <Box mb="1em">
      <Text mb="1em" variant="title">
        {data.Title}
      </Text>
      <div dangerouslySetInnerHTML={{__html: data.Text ?? ''}} />
    </Box>
  );
}
