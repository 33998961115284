import {Box, Flex, Image, Text} from '@chakra-ui/react';
import React from 'react';
import {Group} from 'types/NodeType';
import plus from '../../assets/plus.svg';
import ScheduleNodeComponent from './ScheduleNodeComponent';

interface Props {
  Group: Group;
  Disabled: boolean;
}
export default function ScheduleGroupComponent(Content: Props): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);

  const groupList = [...(Content.Group?.Groups ?? [])];
  const nodeList = [...(Content.Group?.Nodes ?? [])];

  return (
    <Box flex="1" key={Content.Group?.GroupId}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth="2px"
        borderColor="black"
        py="1em"
        onClick={() => setIsOpen(!isOpen)}>
        <Text variant="title" alignSelf="center">
          {Content.Group?.Name}
        </Text>
        {isOpen ? (
          <Box w="16px" h="3px" bg="black" />
        ) : (
          <Image alignSelf="center" src={plus} w="16px" h="16px" />
        )}
      </Flex>
      {isOpen && (
        <Box>
          {!!groupList.length &&
            groupList
              .sort((a, b) => a.SortOrder - b.SortOrder)
              .map((grp: Group) => (
                <ScheduleGroupComponent
                  key={grp.GroupId}
                  Disabled={Content.Disabled}
                  Group={grp}
                />
              ))}
          {!!nodeList.length &&
            nodeList
              .sort((a, b) => a.SortOrder - b.SortOrder)
              .map((node) => (
                <ScheduleNodeComponent
                  key={node.NodeId}
                  Node={node}
                  Disabled={Content.Disabled}
                />
              ))}
        </Box>
      )}
    </Box>
  );
}
