import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import auth from 'authur';
import {setAuth, store} from '../redux/store';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function LogoutModal({isOpen, onClose}: ModalProps): JSX.Element {
  return (
    <Modal onClose={onClose} size="sm" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bekräfta utloggning</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Är du säker på att du vill logga ut?</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outlineInverted"
            mr="1em"
            onClick={() => {
              onClose();
              auth.signout();
              store.dispatch(setAuth(false));
            }}>
            Logga ut
          </Button>
          <Button variant="outline" onClick={onClose}>
            Avbryt
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
