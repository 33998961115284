import {Box, Flex, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {Group, Phase, Project, ProjectState} from 'types/NodeType';
import {colors} from '../../constants';
import {
  AddDaysToDate,
  RemoveTimeFromDate,
} from '../../services/SimpleFunctions';
import ScheduleButtonComponent from './ScheduleButtonComponent';
import ScheduleContentComponent from './ScheduleContentComponent';

export default function ScheduleComponent(): JSX.Element {
  const [sortedPhaseList, setSortedPhaseList] = React.useState<Phase[] | []>();
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );

  const [focusedGroup, setFocusedGroup] = React.useState<Group | undefined>(
    state?.project?.Phases?.find((x) => x.SortOrder === 0)?.Groups[0],
  );

  React.useEffect(() => {
    if (state.project?.Phases.length) {
      setSortedPhaseList(
        state.project?.Phases.slice().sort(
          (n1, n2) => n1.SortOrder - n2.SortOrder,
        ),
      );
    } else {
      setSortedPhaseList([]);
    }
  }, [state]);

  return (
    <Flex flex="1" flexDirection={{base: 'column', lg: 'row'}}>
      {(state === undefined || state.state === 'loading') && (
        <Text>Laddar</Text>
      )}
      {state !== undefined && state.state === 'finished' && (
        <>
          <Box
            flex="1"
            mr={{base: '0', xl: '4em'}}
            mb={{base: '2em', lg: '0em'}}>
            <Text mb="1em" variant="titleBig">
              Schema för dina snickerier
            </Text>
            <Box
              w={{base: '100%'}}
              maxW="700px"
              py="1em"
              px=".5em"
              borderRadius="5px"
              bg={colors.secondary[50]}>
              {sortedPhaseList?.map((phase: Phase) => {
                const groupList = [...phase?.Groups];
                return groupList
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((group: Group) => {
                    const [choicesFilledOut, status] = CountChoices(
                      group,
                      state.project ?? null,
                    );
                    return (
                      <ScheduleButtonComponent
                        key={group.GroupId}
                        title={group.Name}
                        status={status}
                        progress={choicesFilledOut}
                        onClick={() => {
                          window.scrollTo(0, 100);
                          setFocusedGroup(group);
                        }}
                        deadlinePassed={
                          !!state.project &&
                          group.Deadline !== undefined &&
                          RemoveTimeFromDate(
                            AddDaysToDate(
                              state?.project.OriginWhen,
                              group.Deadline,
                            ),
                          ) <= new Date()
                        }
                      />
                    );
                  });
              })}
            </Box>
          </Box>
          <Box
            flex="1"
            mr={{base: '0', xl: '4em'}}
            mb={{base: '2em', lg: '0em'}}>
            <Box w={{base: '100%'}} maxW="700px" px=".5em" borderRadius="5px">
              {focusedGroup === undefined ? (
                <Text variant="title" py="1em" px=".5em" p="1em">
                  Välj en punkt i listan för att starta
                </Text>
              ) : (
                <ScheduleContentComponent
                  Disabled={
                    !!state.project &&
                    focusedGroup.Deadline !== undefined &&
                    RemoveTimeFromDate(
                      AddDaysToDate(
                        state?.project.OriginWhen,
                        focusedGroup.Deadline,
                      ),
                    ) <= new Date()
                  }
                  Group={focusedGroup}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Flex>
  );
}

function CountChoices(group: Group, project: Project | null): [string, string] {
  let count = 0;
  let totalCount = 0;
  let nonMandatory = 0;
  if (group?.Groups?.length) {
    const [c, t] = CountGroupChoices(group);
    count += c;
    totalCount += t;
  }
  if (group?.Nodes?.length) {
    const filledItems = group.Nodes?.filter((node) => {
      if (
        node.NodeProperties?.find(
          (prop) => prop.Type === 'mandatory' && prop.Value === 'false',
        ) ||
        node.Type === 'information'
      ) {
        nonMandatory += 1;
        return null;
      }
      return node.Value;
    });
    count += filledItems.length;
    totalCount += group?.Nodes?.length;
  }

  const today = new Date();
  const oneWeekAgo = RemoveTimeFromDate(new Date());
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  const passedDeadline =
    project !== null &&
    group.Deadline !== undefined &&
    RemoveTimeFromDate(AddDaysToDate(project.OriginWhen, group.Deadline)) <=
      today;

  const oneWeekToDeadline =
    !!project &&
    group.Deadline !== undefined &&
    RemoveTimeFromDate(AddDaysToDate(project.OriginWhen, group.Deadline - 7)) <=
      today;

  return [
    count + '/' + (totalCount - nonMandatory),
    count === totalCount - nonMandatory
      ? 'complete'
      : passedDeadline
      ? 'passed'
      : oneWeekToDeadline
      ? 'warning'
      : 'incomplete',
  ];
}

const CountGroupChoices = function f(group: Group): [number, number] {
  let count = 0;
  let totalCount = 0;
  let nonMandatory = 0;
  if (group?.Groups?.length) {
    group.Groups.forEach((grp) => {
      const [c, t] = CountGroupChoices(grp);
      count += c;
      totalCount += t;
    });
  }
  const filledItems = group.Nodes?.filter((node) => {
    if (
      node.NodeProperties?.find(
        (prop) => prop.Type === 'mandatory' && prop.Value === 'false',
      ) ||
      node.Type === 'information'
    ) {
      nonMandatory += 1;
      return null;
    }
    return node.Value;
  });
  return [
    (count += filledItems?.length ?? 0),
    group?.Nodes?.length ? group?.Nodes?.length - nonMandatory : 0 + totalCount,
  ];
};
