import {Box, Link, Text} from '@chakra-ui/react';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store';
import {Group, ProjectState} from 'types/NodeType';
import {colors} from '../../constants';
import {
  AddDaysToDate,
  GiveMePrintableDate,
} from '../../services/SimpleFunctions';
import ScheduleGroupComponent from './ScheduleGroupComponent';
import ScheduleNodeComponent from './ScheduleNodeComponent';

interface Props {
  Group: Group;
  Disabled: boolean;
}

export default function ScheduleContentComponent(Content: Props): JSX.Element {
  const state = useSelector(
    (state: RootState) => state.project as ProjectState,
  );
  const groupList = [...(Content.Group?.Groups ?? [])];
  const nodeList = [...(Content.Group?.Nodes ?? [])];

  return (
    <Box flex="1" key={Content.Group?.GroupId}>
      <Text variant="titleBig">{Content.Group?.Name}</Text>
      <Text
        fontWeight="semibold"
        fontSize="18px"
        color={colors.system.error[500]}>
        Deadline{' '}
        {GiveMePrintableDate(
          AddDaysToDate(
            state.project?.OriginWhen as Date,
            Content.Group?.Deadline ?? 0,
          ),
        )}
      </Text>
      <Text fontWeight="semibold" fontSize="18px">
        {Content.Group?.Description}
      </Text>
      {Content.Group?.WebLink && (
        <Link
          fontWeight="semibold"
          textDecor="underline"
          isExternal
          href={Content.Group.WebLink}>
          {Content.Group.WebLinkText}
        </Link>
      )}
      <Box>
        {!!groupList.length &&
          groupList
            .sort((a, b) => a.SortOrder - b.SortOrder)
            .map((grp: Group) => (
              <ScheduleGroupComponent
                key={grp.GroupId}
                Group={grp}
                Disabled={Content.Disabled}
              />
            ))}
        {!!nodeList.length &&
          nodeList
            .sort((a, b) => a.SortOrder - b.SortOrder)
            .map((node) => (
              <ScheduleNodeComponent
                key={node.NodeId}
                Node={node}
                Disabled={Content.Disabled}
              />
            ))}
      </Box>
    </Box>
  );
}
