import {Box, Button, Flex, Input, Text} from '@chakra-ui/react';
import React from 'react';
import {ChangePassword} from 'services/api';
import {colors} from '../constants';
export default function Changepasswordcomponent(): JSX.Element {
  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [showPasswordErrorLength, setShowPasswordErrorLength] =
    React.useState(false);
  const [showPasswordErrorIdentical, setShowPasswordErrorIdentical] =
    React.useState(false);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordAgain, setNewPasswordAgain] = React.useState('');

  async function Change() {
    setShowError(false);
    setShowPasswordErrorIdentical(false);
    setShowPasswordErrorLength(false);
    if (newPassword.length > 5) {
      if (newPassword === newPasswordAgain) {
        const changed = await ChangePassword({
          Old: oldPassword,
          New: newPassword,
        });
        if (changed) {
          setShow(false);
          setOldPassword('');
          setNewPassword('');
          setNewPasswordAgain('');
        } else setShowError(true);
      } else setShowPasswordErrorIdentical(true);
    } else setShowPasswordErrorLength(true);
  }

  return (
    <Box my="1em">
      <Text mb="1em" variant="title">
        Lösenord
      </Text>
      {show && (
        <>
          <Box>
            <Text
              alignSelf="flex-start"
              fontSize="12px"
              textColor={colors.black}>
              Nuvarande lösenord
            </Text>
            <Input
              placeholder="*******"
              maxW="400px"
              type="password"
              title={oldPassword}
              bg={colors.primary[550]}
              fontSize="18px"
              variant="outline"
              mb="1em"
              onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                setOldPassword(val.target.value)
              }
            />
            <Text
              alignSelf="flex-start"
              fontSize="12px"
              textColor={colors.black}>
              Nytt lösenord
            </Text>
            <Input
              placeholder="*******"
              maxW="400px"
              title={newPassword}
              bg={colors.primary[550]}
              fontSize="18px"
              variant="outline"
              mb="1em"
              onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                setNewPassword(val.target.value)
              }
            />
            <Text
              alignSelf="flex-start"
              fontSize="12px"
              textColor={colors.black}>
              Nytt lösenord igen
            </Text>
            <Input
              placeholder="*******"
              maxW="400px"
              title={newPasswordAgain}
              bg={colors.primary[550]}
              fontSize="18px"
              variant="outline"
              mb="1em"
              onChange={(val: React.ChangeEvent<HTMLInputElement>) =>
                setNewPasswordAgain(val.target.value)
              }
            />
            {showPasswordErrorLength && (
              <Text mb="1em" color={colors.system.error[500]}>
                Lösenordet måste vara minst 6 tecken.
              </Text>
            )}
            {showPasswordErrorIdentical && (
              <Text mb="1em" color={colors.system.error[500]}>
                Lösenorde matchar inte varandra.
              </Text>
            )}
            {showError && (
              <Text mb="1em" color={colors.system.error[500]}>
                Kunde inte byta lösenord. Var god försök igen senare.
              </Text>
            )}
          </Box>
        </>
      )}
      <Flex>
        {show && (
          <>
            <Button
              variant="success"
              width="140px"
              size="sm"
              mr="1em"
              onClick={() => Change()}>
              Ändra lösenord
            </Button>
            <Button
              variant="edit"
              width="140px"
              size="sm"
              onClick={() => {
                setShow(false);
                setShowError(false);
                setOldPassword('');
                setNewPassword('');
                setNewPasswordAgain('');
              }}>
              Avbryt
            </Button>
          </>
        )}
        {!show && (
          <Button
            variant={'edit'}
            width="140px"
            size="sm"
            onClick={() => setShow(true)}>
            Byt lösenord
          </Button>
        )}
      </Flex>
    </Box>
  );
}
