import {Button, Image, Text} from '@chakra-ui/react';
import arrowRightIcon from 'assets/arrowRight.svg';
import React from 'react';
import {colors} from '../constants';
type CompModel = {
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};
export function ArrowButtonComponent({title, onClick}: CompModel): JSX.Element {
  return (
    <Button
      borderWidth="0"
      bg={colors.primary[550]}
      w={{base: '100%'}}
      maxWidth="500px"
      color={colors.secondary[700]}
      py="2em"
      px="1em"
      mb="1em"
      justifyContent="space-between"
      onClick={onClick}>
      <Text fontSize="18px">{title}</Text>
      <Image src={arrowRightIcon} h="16px" />
    </Button>
  );
}
